<template>
  <b-row>
    <b-col sm="12" lg="12" xl="12">
      <b-card no-body class="">
        <b-card-body class="p-4">
          <form @submit.prevent="validateBeforeSubmit">
            <h1>Add Accountant</h1>
            <p class="text-muted">Enter accountant information</p>
            <b-row>
              <b-col class="col-12 col-lg-12">
                <label>Company</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend style="z-index: 99;">
                    <b-input-group-text><i class="icon-settings"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <select class="select-account form-control" name="companyid" v-validate="'min_value:1'" v-model="companyid">
                    <option value="0" disabled selected>Select Company</option>
                    <option for v-for="option in companyData"  v-bind:value="option.companyid">{{option.name}}</option>
                  </select>
                </b-input-group>
                <i v-show="errors.has('companyid')" class="fa fa-exclamation-triangle mb-3"></i> <span v-show="errors.has('companyid')" class="help is-danger">The company account is required.</span>
                </b-col>
                <b-col class="col-12 col-lg-6">
                </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>First Name</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
                </b-input-group>
                <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
                </b-col>
                <b-col class="col-12 col-lg-6">
                  <label>Last Name</label>
                  <b-input-group class="mb-2">
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
                  </b-input-group>
                  <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
                </b-col>
            </b-row>

            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>Phone Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
                </b-input-group>
                <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>
              </b-col>
              <b-col class="col-12 col-lg-6">
                <label>Fax Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="fax" name="fax" v-validate="'digits:10'" class="form-control" placeholder="Fax Number" />
                </b-input-group>
                <i v-show="errors.has('fax')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax')" class="help is-danger">{{ errors.first('fax') }}</span>
             </b-col>
            </b-row>
            <b-row>
              <b-col class="col-12 col-lg-6">
                <label>Email</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
                </b-input-group>
                <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
              </b-col>
            </b-row>
            <b-row>
            <b-col class="col-12 col-lg-6">
                <label>Password</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="password" v-model="password" v-validate="'required'" name="password" class="form-control" placeholder="Password" ref="password" />
                </b-input-group>
                <i v-show="errors.has('password')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('password')" class="help is-danger">The password is required.</span>
            </b-col>
            <b-col class="col-12 col-lg-6">
              <label>Password Confirmation</label>
              <b-input-group class="mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-lock"></i></b-input-group-text>
                </b-input-group-prepend>
                <input type="password" class="form-control" v-model="password2" v-validate="'required|confirmed:password'" placeholder="Password Confirmation" data-vv-as="password" name="password2" />
              </b-input-group>
              <i v-show="errors.has('password2')" class="fa fa-exclamation-triangle"></i> <span v-show="errors.has('password2')" class="help is-danger">The passwords must match.</span>
              </b-col>
            </b-row>
            <b-row>
              <div class="col-md-12">
                <i v-show="error" class="fa fa-exclamation-triangle mb-2"></i><span v-show="error" class="help is-danger">Error. Possible duplicate email. Please contact admin.</span>
              </div>
            </b-row>
            <b-button variant="success" class="mt-4" type="submit" block>Create Accountant</b-button>
          </form>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import vueSlider from 'vue-slider-component';

export default {
  name: 'AddCompany',
  components: {
    vueSlider
  },
  data: function () {
    return {
      companyData: null,
      companyid: 0,
      email: null,
      password: null,
      authority: null,
      passlimit: null,
      fname: null,
      lname: null,
      fax: null,
      phone: null,
      error: null,
      password2: null,
      slider: {
        value: 3,
        width: 280,
        height: 8,
        dotSize: 20,
        min: 1,
        max: 5,
        interval: 1,
        disabled: false,
        show: true,
        speed: 0.3,
        reverse: false,
        lazy: true,
        tooltip: 'always',
        tooltipDir: [
          "right",
          "right"
        ],
        piecewiseStyle: {
          backgroundColor: '#ccc',
          visibility: 'visible',
          width: '12px',
          height: '12px'
        },
        piecewiseActiveStyle: {
          backgroundColor: '#3498db'
        },
        labelActiveStyle: {
          color: '#3498db'
        },
        piecewise: true
      }
    }
  },
  mounted: function () {
    this.companyid = this.$route.params.id;
    this.$http.get('/admin/get/companies')
    .then(response => {
      if (response.status === 200) {
        this.companyData = response.data.result;
      }
    })
    .catch(error => {
      this.$toasted.show('Error. Cant get all company list', {type: 'error', duration: '3000'})
    })
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            companyid: this.companyid,
            email: this.email,
            password: this.password,
            authority: 6,
            passlimit: 10000000,
            fname: this.fname,
            lname: this.lname,
            fax: this.fax,
            phone: this.phone,
          })
          this.$http.post('/admin/create/manager', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Accountant added successful.', {type: 'success', duration: '3000'})
              this.$router.go(-1);
            }
          })
          .catch(error => {
            this.$toasted.show('Error.', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    }
  }
}
</script>

<style lang="scss">
  .fa-exclamation-triangle {
    color: #ffc107;
    margin-right: 0.5em;
    margin-bottom: 1em;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { sm: "12", lg: "12", xl: "12" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c("b-card-body", { staticClass: "p-4" }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.validateBeforeSubmit.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("h1", [_vm._v("Add Accountant")]),
                    _c("p", { staticClass: "text-muted" }, [
                      _vm._v("Enter accountant information")
                    ]),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-12" },
                          [
                            _c("label", [_vm._v("Company")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  { staticStyle: { "z-index": "99" } },
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "icon-settings" })
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "min_value:1",
                                        expression: "'min_value:1'"
                                      },
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.companyid,
                                        expression: "companyid"
                                      }
                                    ],
                                    staticClass: "select-account form-control",
                                    attrs: { name: "companyid" },
                                    on: {
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.companyid = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "0",
                                          disabled: "",
                                          selected: ""
                                        }
                                      },
                                      [_vm._v("Select Company")]
                                    ),
                                    _vm._l(_vm.companyData, function(option) {
                                      return _c(
                                        "option",
                                        {
                                          attrs: { for: "" },
                                          domProps: { value: option.companyid }
                                        },
                                        [_vm._v(_vm._s(option.name))]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("companyid"),
                                  expression: "errors.has('companyid')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle mb-3"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("companyid"),
                                    expression: "errors.has('companyid')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The company account is required.")]
                            )
                          ],
                          1
                        ),
                        _c("b-col", { staticClass: "col-12 col-lg-6" })
                      ],
                      1
                    ),
                    _c("hr"),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("First Name")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-user" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "fname",
                                    placeholder: "First Name"
                                  },
                                  model: {
                                    value: _vm.fname,
                                    callback: function($$v) {
                                      _vm.fname = $$v
                                    },
                                    expression: "fname"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("fname"),
                                  expression: "errors.has('fname')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("fname"),
                                    expression: "errors.has('fname')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The first name is required.")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Last Name")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-user" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "lname",
                                    placeholder: "Last Name"
                                  },
                                  model: {
                                    value: _vm.lname,
                                    callback: function($$v) {
                                      _vm.lname = $$v
                                    },
                                    expression: "lname"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("lname"),
                                  expression: "errors.has('lname')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("lname"),
                                    expression: "errors.has('lname')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The last name is required.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Phone Number")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-phone fa-flip-horizontal"
                                      })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|digits:10",
                                      expression: "'required|digits:10'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    name: "phone",
                                    placeholder: "Phone Number"
                                  },
                                  model: {
                                    value: _vm.phone,
                                    callback: function($$v) {
                                      _vm.phone = $$v
                                    },
                                    expression: "phone"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("phone"),
                                  expression: "errors.has('phone')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("phone"),
                                    expression: "errors.has('phone')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v(_vm._s(_vm.errors.first("phone")))]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Fax Number")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-fax" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "digits:10",
                                      expression: "'digits:10'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    name: "fax",
                                    placeholder: "Fax Number"
                                  },
                                  model: {
                                    value: _vm.fax,
                                    callback: function($$v) {
                                      _vm.fax = $$v
                                    },
                                    expression: "fax"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("fax"),
                                  expression: "errors.has('fax')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("fax"),
                                    expression: "errors.has('fax')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v(_vm._s(_vm.errors.first("fax")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Email")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-user" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    name: "email",
                                    placeholder: "Email"
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("email"),
                                  expression: "errors.has('email')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("email"),
                                    expression: "errors.has('email')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The email is required.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Password")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-lock" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("b-form-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  ref: "password",
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    name: "password",
                                    placeholder: "Password"
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("password"),
                                  expression: "errors.has('password')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("password"),
                                    expression: "errors.has('password')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The password is required.")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "col-12 col-lg-6" },
                          [
                            _c("label", [_vm._v("Password Confirmation")]),
                            _c(
                              "b-input-group",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "b-input-group-prepend",
                                  [
                                    _c("b-input-group-text", [
                                      _c("i", { staticClass: "fa fa-lock" })
                                    ])
                                  ],
                                  1
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.password2,
                                      expression: "password2"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|confirmed:password",
                                      expression:
                                        "'required|confirmed:password'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    placeholder: "Password Confirmation",
                                    "data-vv-as": "password",
                                    name: "password2"
                                  },
                                  domProps: { value: _vm.password2 },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.password2 = $event.target.value
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("password2"),
                                  expression: "errors.has('password2')"
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle"
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("password2"),
                                    expression: "errors.has('password2')"
                                  }
                                ],
                                staticClass: "help is-danger"
                              },
                              [_vm._v("The passwords must match.")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("b-row", [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.error,
                              expression: "error"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle mb-2"
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.error,
                                expression: "error"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [
                            _vm._v(
                              "Error. Possible duplicate email. Please contact admin."
                            )
                          ]
                        )
                      ])
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-4",
                        attrs: { variant: "success", type: "submit", block: "" }
                      },
                      [_vm._v("Create Accountant")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }